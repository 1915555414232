import React, { ChangeEvent, FC } from 'react';
import style from './style.module.less';

interface Props {
  className?: string;
  items: {
    label: string;
    Input: React.ReactNode;
    className?: string;
  }[];
}

export const InputGrid: FC<Props> = (props) => {
  const { className, items } = props;

  return (
    <div className={`${style.container} ${className ?? ''}`}>
      {items.map((item) => (
        <label
          className={`${style.inputContainer} ${item.className ?? ''}`}
          key={item.label}
        >
          <div className={style.inputLabel}>{item.label}</div>
          {item.Input}
        </label>
      ))}
    </div>
  );
};
