import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { load as botdLoader } from '@fingerprintjs/botd';
import ReCAPTCHA from 'react-google-recaptcha';
import { Header } from '../../components/header';
import { Wrapper } from '../../components/wrapper';
import style from './style.module.less';
import AlertOctagonIcon from '../../assets/icons/alert-octagon.svg?react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import selectors from '../../redux/selectors';
import { Footer } from '../../components/footer';
import { Button } from '../../components/button';
import env from '../../constants/env';
import { useAppDispatch } from '../../redux/hooks';
import { actions } from '../../redux/slices';
import { usePrevious } from '../../hooks/use-previous';
import { ContactUsForm } from './form';

const botdPromise = botdLoader({
  // We disable monitoring so that the botd library doesn't send user identifiable data to the server
  monitoring: false,
});

export const ContactUs = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isBot, setIsBot] = useState(false);

  const isSendingEmail = useSelector(selectors.contactUs.isSendingEmail());
  const prevIsSendingEmail = usePrevious(isSendingEmail);
  // const sendEmailError = useSelector(selectors.contactUs.getSendEmailError());
  const startCase = useCallback(() => {
    navigate('/chat', {
      state: { newCase: true },
    });
  }, [navigate]);

  // this really shd be a component
  useEffect(() => {
    const checkIfBot = async () => {
      try {
        const result = await botdPromise
          .then((botd) => botd.detect())
          .then((result) => result)
          .catch((e) => {
            return { bot: false };
          });
        if (result.bot) {
          // Bot detected
          setIsBot(true);
          return;
        }
        if (recaptchaRef.current) {
          // Send to server to check for bot
          const token = await recaptchaRef.current.executeAsync();

          const result = await dispatch(
            actions.session.recaptcha({
              token,
            })
          ).unwrap();
          if (result.isBot) {
            throw new Error('Bot detected');
          }
        }
      } catch (e) {
        console.error(e);
        setIsBot(true);
      }
    };
    checkIfBot();
  }, [dispatch, navigate]);

  useEffect(() => {
    if (prevIsSendingEmail && !isSendingEmail) {
      setHasSubmitted(true);
    }
  }, [isSendingEmail, prevIsSendingEmail]);

  useEffect(() => {
    // we want to find the button in the bannerText (id: start-case-link) and add styles to it
    const startCaseLink = document.getElementById('start-case-link');
    if (startCaseLink) {
      startCaseLink.classList.add(style.inlineLink);
      startCaseLink.addEventListener('click', startCase);
    }
  }, [startCase, hasSubmitted]);

  useEffect(() => {
    const contactAgain = document.getElementById('reset-form');
    if (contactAgain) {
      contactAgain.classList.add(style.inlineLink);
      const resetHasSubmitted = () => setHasSubmitted(false);
      contactAgain.addEventListener('click', resetHasSubmitted);
    }
  }, [hasSubmitted]);

  return (
    <>
      <Helmet>
        <title>{t('pages.contact-us')}</title>
      </Helmet>
      <Header />
      <Wrapper className={style.container} contentClassName={style.content}>
        <div className={style.heroWrapper}>{t('pages.contact-us')}</div>
        {hasSubmitted ? (
          <div className={style.successContainer}>
            <div className={style.title}>{t('contact.thank-you-title')}</div>
            <div
              className={style.successMessage}
              dangerouslySetInnerHTML={{
                __html: t('contact.thank-you-message'),
              }}
            />
            <div className={style.submitButtonContainer}>
              <Button
                onClick={startCase}
                className={style.submitButton}
                label={t('contact.start-case')}
              />
            </div>
            <div
              className={style.successMessage}
              dangerouslySetInnerHTML={{
                __html: t('contact.contact-again'),
              }}
            />
          </div>
        ) : (
          <>
            <div className={style.bannerWrapper}>
              <AlertOctagonIcon className={style.alertOctagonIcon} />
              <div
                className={style.bannerText}
                dangerouslySetInnerHTML={{ __html: t('contact.banner') }}
              />
            </div>
            <ContactUsForm isBot={isBot} isSendingEmail={isSendingEmail} />
          </>
        )}

        <div className={style.contactDetailsContainer}>
          <div className={style.officeContainer}>
            <div className={style.detailsTitle}>{t('contact.office')}</div>
            <div className={style.detailsContent}>
              Level 8, 207 Kent Street
              <br />
              Sydney
              <br />
              New South Wales
              <br />
              Australia, 2000
            </div>
          </div>
          <div className={style.officeContainer}>
            <div className={style.detailsTitle}>{t('contact.email-label')}</div>
            <div className={style.detailsContent}>
              <span className={style.boldMobile}>
                {t('contact.topic.general')}
              </span>
              <br />
              <a
                href={`mailto:info@${env.PUBLIC_URL.replace(
                  /^https?:\/\//,
                  ''
                )}`}
              >
                info@lawconnect.com
              </a>
            </div>
          </div>
        </div>
      </Wrapper>
      <ReCAPTCHA
        ref={recaptchaRef}
        size='invisible'
        sitekey={env.RECAPTCHA_SITE_KEY}
      />
      <Footer className={style.noMargin} />
    </>
  );
};
