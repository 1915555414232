import { createSlice } from '@reduxjs/toolkit';
import { actions } from '.';
import { AboutStats } from '../../types/about';

interface ContactUsState {
  pending: {
    send?: boolean;
  };
  errors: {
    send?: string | null;
  };
}

const contactUsState: ContactUsState = {
  pending: {},
  errors: {},
};

const contactUsSlice = createSlice({
  name: 'contactUs',
  initialState: contactUsState,
  // you cant have a reducer name match the thunk action
  reducers: {},
  extraReducers: (builder) => {
    // fetch
    builder.addCase(actions.contactUs.send.pending, (state) => {
      state.pending.send = true;
      state.errors.send = null;
    });
    builder.addCase(actions.contactUs.send.fulfilled, (state, action) => {
      state.pending.send = false;
      state.errors.send = null;
    });
    builder.addCase(actions.contactUs.send.rejected, (state, action) => {
      state.pending.send = false;
      state.errors.send = action.error.message;
    });
  },
});

// im not sure if this is good practice but it mimics what we used to do with dispatching actions
export const contactUsActions = contactUsSlice.actions;
export const contactUsReducer = contactUsSlice.reducer;
