import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import style from './style.module.less';
import { useTranslation } from 'react-i18next';
import { InputGrid } from '../../components/input-grid';
import { SelectComponent } from '../../components/select';
import { Button } from '../../components/button';
import selectors from '../../redux/selectors';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { actions } from '../../redux/slices';

export interface FormErrors {
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  message: boolean;
}

interface Props {
  isBot: boolean;
  isSendingEmail: boolean;
}

export const ContactUsForm: FC<Props> = (props) => {
  const { isBot, isSendingEmail } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const topics = useMemo(
    () => [
      {
        id: 'general',
        label: t('contact.topic.general'),
      },
      {
        id: 'tech-support',
        label: t('contact.topic.tech-support'),
      },
      {
        id: 'feedback',
        label: t('contact.topic.feedback'),
      },
      {
        id: 'media',
        label: t('contact.topic.media'),
      },
    ],
    [t]
  );
  const user = useAppSelector(selectors.user.getUser());
  const [firstName, setFirstName] = useState(user?.firstName || '');
  const [lastName, setLastName] = useState(user?.lastName || '');
  const [email, setEmail] = useState(user?.email || '');

  const [topic, setTopic] = useState(topics[0].id);
  const [message, setMessage] = useState('');
  const [hasError, setHasError] = useState<FormErrors>({
    firstName: false,
    lastName: false,
    email: false,
    message: false,
  });

  const updateFirstName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
    setHasError((h) => ({
      ...h,
      firstName: false,
    }));
  }, []);
  const updateLastName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
    setHasError((h) => ({
      ...h,
      lastName: false,
    }));
  }, []);
  const updateEmail = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setHasError((h) => ({
      ...h,
      email: false,
    }));
  }, []);
  const updateTopic = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setTopic(e.target.value);
  }, []);
  const updateMessage = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
    setHasError((h) => ({
      ...h,
      message: false,
    }));
  }, []);

  const handleSubmit = useCallback(() => {
    if (isBot || isSendingEmail) return;
    const newErrors = {} as FormErrors;
    if (!firstName?.length) {
      newErrors.firstName = true;
    }
    if (!lastName?.length) {
      newErrors.lastName = true;
    }
    if (!email?.length) {
      newErrors.email = true;
    }
    if (!message?.length) {
      newErrors.message = true;
    }
    if (Object.values(newErrors).some(Boolean)) {
      setHasError(newErrors);
    } else {
      dispatch(
        actions.contactUs.send({
          firstName,
          lastName,
          email,
          topic,
          message,
        })
      );
    }
  }, [
    dispatch,
    email,
    firstName,
    isBot,
    isSendingEmail,
    lastName,
    message,
    topic,
  ]);

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setEmail(user.email);
    }
  }, [user]);

  return (
    <div className={style.formWrapper}>
      <div className={style.title}>{t('contact.contact-form')}</div>
      <div className={style.contactFormInputContainer}>
        <InputGrid
          items={[
            {
              label: t('contact.first-name-label'),
              Input: (
                <input
                  type='text'
                  className={`${style.input} ${
                    hasError.firstName ? style.hasError : ''
                  }`}
                  value={firstName}
                  onChange={updateFirstName}
                />
              ),
            },
            {
              label: t('contact.last-name-label'),
              Input: (
                <input
                  type='text'
                  className={`${style.input} ${
                    hasError.lastName ? style.hasError : ''
                  }`}
                  value={lastName}
                  onChange={updateLastName}
                />
              ),
            },
            {
              label: t('contact.email-label'),
              Input: (
                <input
                  type='text'
                  className={`${style.input} ${
                    hasError.email ? style.hasError : ''
                  }`}
                  value={email}
                  onChange={updateEmail}
                />
              ),
            },
            {
              label: t('contact.topic-label'),
              Input: (
                <SelectComponent
                  options={topics}
                  value={topic}
                  onChange={updateTopic}
                />
              ),
            },
            {
              label: t('contact.message-label'),
              Input: (
                <textarea
                  className={`${style.messageInput} ${
                    hasError.message ? style.hasError : ''
                  }`}
                  value={message}
                  onChange={updateMessage}
                />
              ),
              className: style.textareaContainer,
            },
          ]}
        />
        {Object.values(hasError).some(Boolean) && (
          <div className={style.errorContainer}>
            {t('contact.error-message')}
          </div>
        )}
      </div>

      <div className={style.submitButtonContainer}>
        <Button
          onClick={handleSubmit}
          className={style.submitButton}
          label={t('contact.submit-button')}
          disabled={isBot || isSendingEmail}
        />
      </div>
    </div>
  );
};
