import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';

const STORE = 'contactUs';
const aboutThunk = {
  send: createAsyncThunk(
    `${STORE}/send`,
    async (args: {
      firstName: string;
      lastName: string;
      email: string;
      topic: string;
      message: string;
    }, { rejectWithValue }) => {
      try {
        const response = await api.contactUs.sendEmail(args);
        return response;
      } catch (error) {
        console.error('failed to send email', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
};

export default aboutThunk;
