import React, { FC, SelectHTMLAttributes } from 'react';
import style from './style.module.less';
import ChevronDown from '../../assets/icons/chevron-down.svg?react';

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  options: {
    id: string;
    label: string;
  }[];
  containerClassName?: string;
}

export const SelectComponent: FC<Props> = ({
  options,
  containerClassName,
  className,
  ...props
}) => {
  return (
    <div className={`${style.container} ${containerClassName ?? ''}`}>
      <select {...props} className={`${style.select} ${className ?? ''}`}>
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.label}
          </option>
        ))}
      </select>
      <div className={style.arrowContainer}>
        <ChevronDown className={style.arrow} />
      </div>
    </div>
  );
};
